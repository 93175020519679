<template>
  <loading :active="isLoading" :is-full-page="fullPage" :loader="loader" />
  <div class="w-full min-h-screen px-6 bg-teal-900">
    <AdminHeader />

    <!-- Main Content -->
    <div class="max-w-4xl pb-8 mx-auto">
      <!-- Title -->
      <h1 class="mb-10 text-2xl text-left text-white">
        Invoice/Receipt
        <span class="-ml-1 text-4xl" style="color: #00A388">.</span>
      </h1>
      <!-- Invoice Content -->
      <div
        v-if="invoice"
        class="relative p-8 bg-white rounded-lg print-content"
      >
        <!-- Top Container with Invoice Number, Billing Info, and Logo -->
        <div class="flex content-between gap-8">
          <!-- Left Container (70%) -->
          <div class="w-full md:w-3/4">
            <!-- Invoice Number -->
            <div style="color:#00BCD4;" class="mb-8 text-2xl text-left ">
              {{ invoice?.invoice_number }}
            </div>

            <!-- Billing Info -->
            <div class="grid grid-cols-1 gap-8 text-left md:grid-cols-2">
              <!-- Billed From -->
              <div class="md:border-r md:border-gray-200 md:pr-8">
                <h3 style="color:#00BCD4;" class="mb-2 text-sm">Billed from</h3>
                <p class="text-gray-600">Q&GO</p>
                <p class="text-gray-600">PO Box 999</p>
                <p class="text-gray-600">Brisbane</p>
                <p class="text-gray-600">QLD 4000</p>
                <p class="text-gray-600">accounts@qandgo.app</p>
                <p class="text-gray-600">P: 1300 000 000</p>
                <p class="text-gray-600">ABN: 13 644 019 063</p>
              </div>

              <!-- Billed To -->
              <div class="md:pl-8">
                <h3 style="color:#00BCD4;" class="mt-6 mb-2 text-sm md:mt-0">
                  Billed to
                </h3>
                <p class="text-gray-600">
                  {{ invoice?.vendor?.business_name }}
                </p>
                <p class="text-gray-600">{{ invoice?.address?.address }}</p>
                <p class="text-gray-600">
                  {{ invoice?.address?.suburb }}
                  {{ invoice?.address?.state_name }}
                  {{ invoice?.address?.postcode }}
                </p>
                <p class="text-gray-600">{{ invoice?.user?.email }}</p>
                <!-- <p class="text-gray-600">{{ invoice?.address?.country }}</p> -->
                <p class="text-gray-600">
                  P: {{ invoice?.user?.country_code }}
                  {{ invoice?.user?.phone_number }}
                </p>
                <p class="text-gray-600">ABN: {{ invoice?.vendor?.abn }}</p>
              </div>
            </div>
          </div>

          <!-- Right Container (30%) -->
          <div class="w-[30%] flex justify-end hidden md:block">
            <img
              src="@/assets/img/qandgo-01.svg"
              alt="Q&G Logo"
              class="object-contain w-32 h-32"
            />
          </div>
        </div>

        <!-- Rest of the content -->
        <div class="mt-8">
          <!-- Payment Details -->
          <div
            class="grid grid-cols-3 gap-8 pb-6 mt-8 mb-6 border-b border-gray-200"
          >
            <div class="pr-4 border-r border-gray-200">
              <h3 style="color:#00BCD4;" class="mb-2 text-sm">Purchase Date</h3>
              <p class="text-gray-600">{{ formatDate(invoice?.created_at) }}</p>
            </div>
            <div class="px-4 border-r border-gray-200">
              <h3 style="color:#00BCD4;" class="mb-2 text-sm">
                Payment Method
              </h3>
              <p class="text-gray-600">
                MC ending in {{ invoice?.paymentmethod_id?.slice(-4) }}
              </p>
            </div>
            <div class="pl-4">
              <h3 style="color:#00BCD4;" class="mb-2 text-sm">
                Payment Status
              </h3>
              <p class="text-gray-600">{{ invoice?.status }}</p>
            </div>
          </div>

          <!-- Invoice Items -->
          <table class="w-full mb-28 ">
            <thead>
              <tr class="text-left">
                <th style="color:#00BCD4;" class="pb-4 text-sm font-normal">
                  Qty
                </th>
                <th
                  style="color:#00BCD4;"
                  class="pb-4 text-[#00BCD4] text-sm font-normal"
                >
                  Description
                </th>
                <th
                  style="color:#00BCD4;"
                  class="pb-4 text-[#00BCD4] text-sm font-normal text-right"
                >
                  Amount
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="py-2 text-left text-gray-600">1</td>
                <td class="py-2 text-left text-gray-600">
                  {{ invoice?.transaction?.transaction_desc }}
                </td>
                <td class="py-2 text-right text-gray-600">
                  ${{ invoice?.amount }}
                </td>
              </tr>
            </tbody>
          </table>

          <!-- Total -->
          <div
            style="background-color: #00a388"
            class="bg-[#00A388] #00A388 text-white py-6 px-10 rounded-lg flex justify-between items-center"
          >
            <span>Total</span>
            <span>${{ invoice?.amount }}</span>
          </div>
        </div>
      </div>

      <!-- Action Buttons -->
      <div class="flex justify-between mt-6 no-print">
        <button
          style="background-color:#204952 ;"
          @click="$router.back()"
          class="px-6 py-4 bg-[#204952] text-white rounded-md flex items-center gap-2"
        >
          <span class="text-xl material-icons arrow-left-icon">arrow_left</span>
          Back
        </button>

        <button
          style="background-color: #00a388"
          @click="printInvoice"
          class="px-6 py-4 bg-[#00A388] text-white rounded-md"
        >
          Print
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import AdminHeader from "@/components/AdminHeader.vue";
import Loading from "vue-loading-overlay";

export default {
  name: "VendorInvoiceDetails",
  components: {
    AdminHeader,
    Loading,
  },
  data() {
    return {
      isLoading: false,
      fullPage: true,
      loader: "bars",
      invoice: null,
    };
  },
  mounted() {
    console.log("Component mounted");
    console.log("Current route:", this.$route);
    console.log("Axios defaults:", axios.defaults);
    this.fetchInvoiceDetails();
  },
  methods: {
    async fetchInvoiceDetails() {
      const vendorId = this.$route.query.id;
      const invoiceId = this.$route.query.invoice_id;

      if (!vendorId || !invoiceId) {
        this.$router.push({
          name: "AdminVendorInvoices",
          query: { id: vendorId },
        });
        return;
      }

      try {
        this.isLoading = true;
        const response = await axios.get(
          `/user/${vendorId}/invoice/${invoiceId}`,
          {
            headers: {
              Authorization: `Bearer ${
                JSON.parse(localStorage.getItem("access")).access_token
              }`,
            },
          }
        );

        if (response.data.success) {
          this.invoice = {
            ...response.data.data.invoice,
            vendor: response.data.data.vendor,
            address: response.data.data.address,
            user: response.data.data.user,
            transaction: response.data.data.transaction, // Transaction data
          };
        } else {
          throw new Error(response.data.message || "Failed to fetch invoice");
        }
      } catch (error) {
        console.error("Error fetching invoice:", error);
        this.$swal.fire({
          icon: "error",
          title: error.message || "Failed to fetch invoice details",
          toast: true,
          position: "top-end",
          timer: 3000,
          showConfirmButton: false,
        });

        this.$router.push({
          name: "AdminVendorInvoices",
          query: { id: vendorId },
        });
      } finally {
        this.isLoading = false;
      }
    },

    formatDate(dateString) {
      if (!dateString) return "";
      const date = new Date(dateString);
      return date.toLocaleDateString("en-US", {
        day: "numeric",
        month: "long",
        year: "numeric",
      });
    },

    printInvoice() {
      window.print();
    },
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");

.bg-teal-900 {
  background-color: #06262d;
}

.arrow-left-icon {
  color: #00a388;
  background-color: #002f28;
  display: inline-flex;
  border-radius: 4px;
}

/* Print Styles */
@media print {
  /* Hide everything by default */
  body * {
    visibility: hidden;
  }

  /* Show only the invoice content and its children */
  .print-content,
  .print-content * {
    visibility: visible;
  }

  /* Position the invoice content at the top of the page */
  .print-content {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    background: white !important;
    padding: 20px !important;
  }

  /* Hide header and buttons */
  .no-print,
  button {
    display: none !important;
  }

  /* Force two-column layout for billing info */
  .grid-cols-1 {
    grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
  }

  /* Show logo in print */
  .hidden {
    display: block !important;
  }

  /* Ensure proper colors */
  [style*="background-color: #00a388"] {
    background-color: #00a388 !important;
    color: white !important;
    -webkit-print-color-adjust: exact !important;
    print-color-adjust: exact !important;
  }

  [style*="color:#00BCD4"] {
    color: #00bcd4 !important;
    -webkit-print-color-adjust: exact !important;
    print-color-adjust: exact !important;
  }

  /* Show borders in print */
  .md\:border-r {
    border-right-width: 1px !important;
  }

  /* Ensure proper spacing */
  .md\:pr-8 {
    padding-right: 2rem !important;
  }

  .md\:pl-8 {
    padding-left: 2rem !important;
  }
}

/* Set print page margins */
@page {
  margin: 0.5cm;
  size: A4;
}
</style>
